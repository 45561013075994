import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import styles from '../components/article.module.scss'
import richTextStyles from '../components/rich-text.module.scss'

import VideoListing from '../components/video-listing'
import UserGuide from '../components/user-guide'



import Collapsible from '../components/collapsible-element'

import LeadSection from '../components/lead-section'
import UpdateLead from '../components/update-lead'
import ContactCollapsible from '../components/contact-collapsible'
import ProductUpdateTable from '../components/product-update-table'



import RichTextRenderer from '../components/rich-text-renderer'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import MetadataGenerator from '../components/metadata-generator'




class ListItemProductNavigation extends React.Component {
  render() {
    const product = this.props.product;
    const currentProductSlug = this.props.currentSlug;
    var listClass = ''
    var link = `/${product.slug}/software-updates/latest-software-versions`;
    var linkEnd = `/`;
    if(product.slug == "mobile-app") {
      linkEnd = `-mobile-app/`;
    }
    if(product.slug == "youview-on-sony") {
      linkEnd = `-sony/`;
    }
    if(currentProductSlug == product.slug) {
      listClass = 'active';
    }
    else {
      listClass = false;

    }
    var name = product.title;
    link += linkEnd;
  return (
    <li className={listClass}><Link to={link}>{name}</Link></li>
    )
}
}


// class InlineEntry extends React.Component {
//   render() {
//     const product = this.props.product
//     var link = fields.slug[`en-US`]
//     var linkReact = <Link to={`/${fields.productReference['en-US'].fields.slug['en-US']}/${fields.articleCategory['en-US'].fields.slug['en-US']}/${fields.slug['en-US']}/`}>{fields.title[`en-US`]}</Link>
//     console.log(fields)

//     return (
      
//       <span> {linkReact} </span>
      
//       )
//   }
// }



class PageTemplate extends React.Component {
     
  render() {

    const post = get(this.props, 'data.contentfulSupportSitePage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')    
    const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')    

    const videos = get(this, 'props.data.allContentfulSupportSiteVideo.edges');
    const userGuides = get(this, 'props.data.allContentfulSupportSiteUserGuide.edges');
    const contactSections = get(this, 'props.data.allContentfulSupportSiteContactSection.edges');

    const supportPages = get(this, 'props.data.allContentfulSupportSitePage.edges');

    const updateTables = get(this, 'props.data.allContentfulSupportSiteProductUpdateTable.edges');

    const supportProducts = get(this, 'props.data.allContentfulSupportSiteProduct.edges');

    const headerImageTest = get(this, 'props.data.contentfulAsset');
    // 
    const supportChangesContacts = get(this, 'props.data.supportChangesContacts');

    const context = get(this, 'pageContext')
    console.log('context')
    if(post === null) {
      console.log('post is null')
      console.log(supportChangesContacts.nodes[0])
    }
    console.log(this.props.data.contentfulSupportSitePage)
    
    




    const spec = this.props.pageContext.pageType;

    var pageTitle = '';

    if(this.props.pageContext.pageType){
      //console.log("context is "+spec);
    }

    var excludedSlug = 'mobile-app';

    




    
    var numbered;

    var productSlug = false;

    var largeArticle = false;

    var specialPage
    var videoContents = '';
    var guideContents = '';
    var contactContents = '';
    var updateContents = '';
    var sectionNavigation = '';
    var supportUpdates = '';

    //check eyebrow
    var eyebrow = '';

    var openingHTML = ''
    var openingHTMLUpdate = '' 
    var articleStepHTML = ''

    var articleContent = ''
    var articleContentLead = ''
    var articleClass = 'article'

    var panelID = 'accordion'
    var panelRole = 'tablist'
    var panelAria = 'true'

    //const spec = pageContext
    if(post) {
      pageTitle = post.title;
    }
    
    if(post && post.specialPageType) {
      specialPage = post.specialPageType
    
    }
    
    if(post === null && this.props.pageContext.slug == 'support-changes') {
      specialPage = "Support Update"
    }

    if((this.props.pageContext.pageType && spec == 'LatestUpdates') || (specialPage == "Latest Versions")) {
      var productTitleString = "box";
      eyebrow = "A YouView Box FAQ";
      if(post.pageProduct.slug == "mobile-app") {
        productTitleString = "app";
        eyebrow = "A Mobile App FAQ";

      }
      else if (post.pageProduct.slug == "youview-on-sony") {
        productTitleString = "sony";
        eyebrow = "A YouView on Sony FAQ"

      }
      pageTitle = "Latest " + productTitleString + " versions";
      //pageTitle += this.props.pageContext.slug;

      openingHTML = '<p>Whether you’re looking for information on updates for YouView boxes, YouView on Sony or the Mobile App, everything you need is in the below platform sections.</p>';
    }


if(specialPage == "Support Update") {
  console.log('activate special type')
  pageTitle = "Important Information regarding our Community Forum & Social Media channels"
  var supportArticleClass = [styles.articleWide, richTextStyles.article].join(' ')
  if(post === null) {
    supportUpdates = <><div itemprop="articleBody" class={supportArticleClass}><p>As of July 2023 our Community Forum and social media support channels will be closed.</p><p>Support articles for Humax Retail and Sony TVs with YouView can still be found at <a href="https://support.youview.com">support.youview.com</a></p><p>Please see the below support information for your box and/or internet service provider</p></div><div class="panel-group support contact" id="contact" role="tablist" aria-multiselectable="true"><ContactCollapsible key={supportChangesContacts.nodes[0].id} contactCategory={supportChangesContacts.nodes[0]} /></div></>
  }
  else {
    supportUpdates = <div class="panel-group support contact" id="contact" role="tablist" aria-multiselectable="true"><ContactCollapsible key={supportChangesContacts.nodes[0].id} contactCategory={supportChangesContacts.nodes[0]} /></div>
  }

  console.log(contactSections)
  
}

    

    if(specialPage == "Videos & Guides") {
      videoContents = <section className={"grey"}>
          <div className={'container'}>
            <div className={'row'}>
            <div className={'lead-section text-center'}>
                <h2 className={'section-header'}>Our Help Videos</h2>
              </div>
            </div>
          </div>
          <VideoListing videoNodes={videos} />
          </section>;
    }
    if(specialPage == "Videos & Guides") {
      guideContents = <section>
          <div className={'container'}>
            <div className={'row'}>
            <div className={'lead-section text-center'}>
                <h2 className={'section-header'}>User Guides</h2>
                <p>Not sure which YouView box you have? Follow <a href="https://support.youview.com/youview-box/box-and-remote/checking-your-box-model">these steps</a>.</p>
              </div>
            </div>
         
          {userGuides.map(({ node }) => {
               return (
                 <div key={node.id} className={" col-sm-4 guide-holder"}>
                     <UserGuide  guide={node}/>
                     </div>
               )
             })}
             </div>
          </section>
    }

    if(specialPage == "Contact Page") {
      contactContents = <div class="panel-group support contact" id="contact" role="tablist" aria-multiselectable="true">
              {contactSections.map(({ node }) => {
                return (
                    <ContactCollapsible key={node.id} contactCategory={node} />
                )
              })}
              </div>
    }

    if((this.props.pageContext.pageType && spec == 'LatestUpdates') || (specialPage == "Latest Versions")){
      //console.log(post.slug)
          updateContents = <section>
            <div className={'container'}>
              {updateTables.map(({ node }, i) => {
                  return (
                    <ProductUpdateTable currentSlug={post.pageProduct.slug} key={node.id} productUpdateTable={node} />
                  )
                })}
            </div>
            </section>

            sectionNavigation = <ul id={"filters-new"} className={"nav nav-pills"}>
          {supportProducts.map(({ node }) => {
            if(node.slug != excludedSlug) {
              return (
                <ListItemProductNavigation currentSlug={post.pageProduct.slug} product={node} />
                
               )
            }
               
             })}
          </ul>
          }
   

    if(post && post.pageProduct) {
      eyebrow = "A "+post.pageProduct.title+" FAQ"
      productSlug = post.pageProduct.slug

    }
    if(post && post.pageProduct && post.pageCategory && post.pageCategory.slug == 'software-updates') {
      eyebrow = "A "+post.pageProduct.title+" Update"
    }


    //check article contents

    

    var manufacturerPlatform = false;
    var updateDate = false

    var articleItemProp = false;
    var stepsItemProp = false;

    var updateHeading = "no"

    var metaDescription = 'NextGen Youview Support Site'

    if (post && post.manufacturerPlatform) {
      manufacturerPlatform = post.manufacturerPlatform;
    }
    if (post && post.updateDate) {
      updateDate = post.updateDate;
    }

    var containerClass = 'panel-group support '

    if(post && post.body) {
      articleContent = post.body.json
      articleContentLead = post.body.json
    }
    if(post){
      if(!post.articleSteps) {
        articleStepHTML = '';
        articleContentLead = '';
        articleClass = styles.articleWide
        articleItemProp = "articleBody"
        largeArticle = true
      }
      else {
        if( post.pageCategory.slug == 'software-updates') {
          articleStepHTML = post.articleSteps.map((ContentfulArticleStep, i) => (
            <UpdateStep product={productSlug} numbered={numbered} iterator={i} key={ContentfulArticleStep.id} step={ContentfulArticleStep} />
            ))
          updateHeading = "yes"
          panelID = false;
          panelRole = false;
          panelAria = false;
          containerClass = 'update'
          openingHTMLUpdate = openingHTML;
          stepsItemProp = "articleBody"

        }
        else {
          stepsItemProp = "articleBody"
          articleStepHTML = post.articleSteps.map((ContentfulArticleStep, i) => (
            <Collapsible numbered={numbered} iterator={i} key={ContentfulArticleStep.id} step={ContentfulArticleStep} />
            ))
        }
        articleContent = ''

      }

   

    if(!post.description) {
      openingHTML = ''
    }
    else {
      openingHTML = post.description.childMarkdownRemark.html
      //strip tags for meta
      metaDescription = openingHTML.replace(/<\/?[^>]+(>|$)/g, "");
    }


    if(!post.numberedSteps) {
      numbered = '';
    }
    else if (post.numberedSteps == true) {
      numbered = 'numbered'
    }
    else if (post.numberedSteps == false){
      numbered = '';
    }
  } //end big if post

    return (
      <Layout headerImage={headerImageTest} location={this.props.location} title={pageTitle}>
      <MetadataGenerator 
        title={pageTitle} 
        siteTitle={siteTitle} 
        description={metaDescription} 
        urlBase={siteUrl}
        url={this.props.location.href} />
        <section className={styles.white}>
        <article role={"main"} itemScope={"true"}  itemType={"https://schema.org/TechArticle"}>
        
                    <div className={"container"}>
                    <LeadSection
                    title={pageTitle} 
                    eyebrow={eyebrow} 
                    subheading={openingHTML}
                    description={articleContentLead}
                    largeArticle={largeArticle}
                     />
                     <RichTextRenderer containerClass={articleClass} itemProp={articleItemProp} articleJSON={articleContent} />
                     

            
          </div>
          <UpdateLead title={pageTitle} subheading={openingHTML} display={updateHeading} platform={manufacturerPlatform} updateDate={updateDate}/>
          <div itemProp={stepsItemProp} className={containerClass + numbered} id={panelID} role={panelRole} aria-multiselectable={panelAria}>
         {articleStepHTML}  
          </div>
          {videoContents}
          {guideContents}
          {contactContents}
          {sectionNavigation}
          {updateContents}
          {supportUpdates}
          </article>
        </section>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulSupportSitePage(slug: { eq: $slug }) {
            title 
      slug
      description {
        childMarkdownRemark {
          html
        }
      }
      specialPageType
      pageStep {
        ... on ContentfulSupportSiteArticleStep {
          title
          stepContent {
            json
          }
        }
      }
      pageProduct {
        title
        slug
      }
      body {
        json
      }
      pageTopic {
        title
        slug
      }
    }
    #start videos
    allContentfulSupportSiteVideo {
      edges {
        node {
          title
          videoLink
          id
          videoPlaceholder
        }
      }
    }
    #end videos
    #start user guides
      allContentfulSupportSiteUserGuide { 
      edges {
        node {
          id
          title
          platformManufacturer {
            title
            logo {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
          }
          logo {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
          userManuals {
            ... on ContentfulSupportSiteUserManual {
              title
              link
              id
            }
          }
        }
      }
    }
    #end user guides
    allContentfulSupportSiteProduct(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
    #start product tables
    allContentfulSupportSiteProductUpdateTable(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          platformManufacturer {
            ... on ContentfulSupportSiteProductPlatform {
              logo {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          tableProduct {
            ... on ContentfulSupportSiteProduct {
              title
              slug
            }
          }
          titleImage {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
          updateTable {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    #end product tables
    contentfulSupportSiteOverview(title:{eq:"Overview"}) {
        title
        
        #start useful contacts
        usefulContactsOrder {
          ... on ContentfulSupportSiteContactSection {
            id
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            contacts {
              ... on ContentfulSupportSiteContact {
                id
                title
                logo {
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                }
                number {
                  childMarkdownRemark {
                    html
                  }
                }
                lineDetails {
                  childMarkdownRemark {
                    html
                  }
                }
                website {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
        #end useful contacts
        #start help videos
        helpVideoOrder {
          ... on ContentfulSupportSiteVideo {
            title
            videoLink
            id
            videoPlaceholder
          }
        }
        #end help videos
        #start guide order
        userGuideOrder {
          ... on ContentfulSupportSiteUserGuide {
            id
            title
            logo {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            userManuals {
              ... on ContentfulSupportSiteUserManual {
                id
                title
                link
              }
            }
          }
        }
        #end guide order
      }
       allContentfulSupportSitePage {
        edges {
          node {
            slug
            specialPageType
            pageProduct{
              slug
            }
            pageTopic{
              slug
            }
          }
        }
      }
    allContentfulSupportSiteContactSection {
      edges {
        node {
          title
          id
          description {
            childMarkdownRemark {
              html
            }
          }
          contacts {
            ... on ContentfulSupportSiteContact {
              id
              title
              logo {
                file {
                  details {
                    image {
                      width
                      height
                    }
                  }
                  url
                }
              }
              number {
                childMarkdownRemark {
                  html
                }
              }
              lineDetails {
                childMarkdownRemark {
                  html
                }
              }
              website {
                childMarkdownRemark {
                  html
                }
              }

            }
          }
        }
      }
    }
    contentfulAsset(file: {fileName: {eq: "youview-support-background.jpg"}}, fluid: {}) {
      fixed(width: 1800, quality: 100) {
        base64
        aspectRatio
        width
        height
        src
        srcSet
        srcWebp
        srcSetWebp
      }
    }
    supportChangesContacts: allContentfulSupportSiteContactSection(filter: {title: {eq: "Box & TV service providers"}}) {
      nodes {
        title
        id
        description {
          childMarkdownRemark {
            html
          }
        }
        contacts {
          ... on ContentfulSupportSiteContact {
            id
            title
            logo {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
                url
              }
            }
            number {
              childMarkdownRemark {
                html
              }
            }
            lineDetails {
              childMarkdownRemark {
                html
              }
            }
            website {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`
